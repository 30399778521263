<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :submit_preprocessing="submit_preprocessing"
            :slot_table_list="['operation','status']"
            ref="list"
        >
		<template slot="left_btn">
			<a-button type="primary" v-if="this.$store.state.page_auth.batch" style="margin-left: 15px;"
				@click="transfer_status">批量转交</a-button>
		</template>
            <template slot="operation" slot-scope="data">
               <a v-if='data.record.id' @click="handle_details_click(data.record)">查看审批</a>
            </template>
			<template slot="status" slot-scope="data">
			<span>
			{{getLabel(data.record.status)}}	
			</span>				
			</template>
			
        </TableList>
		<Details ref="details"></Details>
		
		<!-- 批量修改经销商弹窗 -->
		<a-modal title="批量转交" :visible="transfer_state" @cancel="transfer_cancle" @ok="transfer_grant">
			<div class="has_seleted_customer">
				<div class="select_item">
					<div class="must_dot">原审批人：</div>
					<a
						@click="user_type = 'old_user';user_chose = true">{{old_user.username ? (old_user.username || '请选择') : '请选择'}}</a>
				</div>
				<div class="select_item">
					<div class="must_dot">转交人</div>
					<a
						@click="user_type = 'new_user';user_chose = true">{{new_user.username ? (new_user.username || '请选择') : '请选择'}}</a>
				</div>
			</div><!-- has_seleted_customer -->
		</a-modal>
		<!-- 人员选中弹窗 -->
		<SelectUser @submit="submit" :visible.sync="user_chose"></SelectUser>
			
    </div>
</template>

<script>
// @ is an alias to /src
import Details from "@/views/freezer/approval/details";
import SelectUser from "@/components/SelectUser";
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import TableList from "@/components/TableList";
import { getBindingApprovalList,getContractInfo,getStatusSelect} from "@/api/freezer";
import { getType,transferApproval } from '@/api/approval'

	const columns = [{
			title: "审批编号",
			dataIndex: "id",
		},
		{
			title: "标题",
			dataIndex: "approval_type_name",
		},
		{
			title: "冰柜型号",
			dataIndex: "goods_code",
		},
		{
			title: "合约时间",
			dataIndex: "term",
		},

		{
			title: "客户名称",
			dataIndex: "customer_name",
		},
		{
			title: "原客户名称",
			dataIndex: "old_customer_name",
		},
		{
			title: "部门",
			dataIndex: "department",
		},

		{
			title: "审批状态",
			dataIndex: "status",
			scopedSlots: {
				customRender: "status"
			}
		},
		{
			title: "审批发起时间",
			dataIndex: "create_time",
		},
		{
			title: "操作",
			width: 150,
			scopedSlots: {
				customRender: "operation"
			}
		},
	];

export default {
    name: "Index",
    components: {
        EditPop,
        Form,SelectUser,
        TableList,Details
    },
    data() {
        return {
			user_type: {},
			old_user: {},
			new_user: '',
			user_chose: false,
			transfer_state: false,
            get_table_list: getBindingApprovalList,
            submit_preprocessing: {
                array_to_string: ['department_id']
            },
            columns,
            form_data_seo: {
                list: [
                    {
                        type: "tree-select",
                        name: "department_id",
                        title: "部门",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
                    {
                        type: "text",
                        name: "keyword",
                        title: "关键字",
                        placeholder: '客户名称/资产编号/申请人',
                        options: {

                        }
                    },
					
                    {
                        type: 'select',
                        name: 'status',
                        title: '状态',
                        list: [],
                        options: {},
                    },
					{
						type: "select",
						name: "user_status",
						title: "在职状态",
						mode: "default",
						options: {},
						list: this.$config.user_status_list
					},
                    {
                    	type: "range_date",
                    	name: "range_date",
                    	title: "创建时间",
                    	options: {},
                    	start: {
                    		name: 'start_time'
                    	},
                    	end: {
                    		name: 'end_time'
                    	},
                    },
                    
                  
                ],
                ...this.$config.form_data_seo
            },

        };
    },
    async created() {
		// 缓存该页面
        this.$method.get_department().then(res => {
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department_id', 'treeData', res)
        });
		getStatusSelect({data:{type:2}}).then(res=>{
			let list = res.data.list
			let arr=[]
			list.forEach(item=>{
				arr.push({value:item.label,key:item.value})
			})
			this.form_data_seo.list[2].list = arr
		})
    },
    methods: {
		getLabel(value){
			let str =''
				this.form_data_seo.list[2].list.forEach(it=>{
					
					if(it.key==value){
						str = it.value
					}
				})
				return str
		},
		handle_details_click(record) {
			this.$refs.details.get_details({approval_id:record.id});
		},
        details(data) {
			this.$keep_route.add(this.$options.name);
            this.$router.push('contract_detail?id=' + data.id)
        },
        transfer_status() {
        	this.transfer_state = true;
        },
        transfer_cancle() {
        	this.transfer_state = false;
        	this.old_user = {};
        	this.new_user = {};
        },
        transfer_grant() {
        	transferApproval({
        		data: {
        			old_user: this.old_user.id,
        			new_user: this.new_user.id,
        			type: 'binding_contract',
        		},
        		info: true
        	}).then(res => {
        		this.transfer_state = false;
        		this.old_user = {};
        		this.new_user = {};
        	})
        },
        submit({
        	data
        }) {
        	this.user_chose = false;
        	this.select_user = data;
        	if (this.user_type == 'old_user') {
        		this.old_user = data
        	}
        	if (this.user_type == 'new_user') {
        		this.new_user = data
        	}
        },
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
	.has_seleted_customer {
		.customer_box {
			.item {
				margin-bottom: 8px;
			}
		}

		.select_item {
			margin-top: 12px;
			display: flex;

			.must_dot {
				min-width: 105px;
			}
		}
	}

</style>